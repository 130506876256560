@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Fira+Mono:wght@700,regular,bold,italic&subset=latin,latin-ext');

@layer components {
    img { 
        image-rendering: optimizespeed;             /* STOP SMOOTHING, GIVE ME SPEED  */
        imaGe-rendering: -moz-crisp-edges;          /* Firefox                        */
        image-rendering: -o-crisp-edges;            /* Opera                          */
        image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
        image-rendering: pixelated;                 /* Universal support since 2021   */
        image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
        -ms-interpolation-mode: nearest-neighbor;   /* IE8+                           */
    }
  .error {
    @apply p-0 m-2 flex flex-col content-center justify-center items-center;
    flex-grow: 1;
  }
  .error h1 {
    @apply text-act1;
  }
  .error a {
    @apply text-xl;
  }

  .sidebar-icon:not(.active) {
    @apply relative flex items-center justify-center
           h-12 w-12 mt-2 mb-2 mx-auto shadow-lg
           text-ina2 bg-ina1
           hover:bg-act1 hover:text-act2
           rounded-3xl hover:rounded-xl transition-all
           ease-linear;
  }
  
  .sidebar-icon.active {
    @apply relative flex items-center justify-center
           h-12 w-12 mt-2 mb-2 mx-auto shadow-lg
           bg-act1 text-act2
           rounded-xl transition-all
           ease-linear;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14
           rounded-md shadow-md
           text-fg bg-act1
           text-xs font-bold
           transition-all scale-0 origin-left;
  }

  .sidebar-divider {
    @apply border-2 rounded-sm mr-2 ml-2 border-act3;
  }

  .breadcrumb-divider {
    @apply border-2 rounded-sm mt-2 mb-2 border-act3 w-0;
  }

  .crumb {
    @apply m-4 content-center self-center text-act2;
  }

  body {
    @apply bg-wall;
  }

  .main {
    @apply fixed left-16 p-12 right-0 top-8 bottom-0;
    overflow-y: auto;
  }
  
  .main-center {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1%;
    padding-right: 1%;
    max-width: 1200px;
  }
    
  .main-error {
    @apply fixed flex left-16 p-12 right-0 top-8 bottom-0
           flex-col content-center;
    overflow-y: auto;
  }

  h1 {
    @apply text-center text-white text-7xl m-8 mt-0 font-bold;
    font-family: 'Roboto'; 
  }
  
  h2 {
    @apply text-white text-5xl mt-4 mb-4 font-bold text-center;
    font-family: 'Roboto'; 
  }
  
  h3 {
    @apply text-white text-3xl mt-6 mb-6 text-center font-bold;
    font-family: 'Roboto'; 
  }

  .frame-bg a {
    @apply no-underline;
  }

  a {
    @apply text-fg underline hover:bg-act1;
    text-decoration-thickness: 2px;
  }

  p, li {
    @apply text-white text-2xl font-bold;
    font-family: 'Fira Mono'; 
    text-align: justify;
    text-justify: inter-word;
  }
  
  .frame-bg {
    @apply rounded-xl 
           bg-[#00000050]
           m-8
           hover:shadow-lg;
  }

  .frame-container {
    @apply rounded-xl overflow-hidden bg-bg
           flex flex-row-reverse transition-all
            items-stretch
           w-[100%] h-[100%] transition-all;
  }

  .frame-bg:hover .frame-container {
    @apply shadow-xl
           -translate-x-3
           -translate-y-3;
  }
  
  .frame-container-noanim {
    @apply rounded-xl overflow-hidden
           bg-bg m-8
           flex flex-row;
  }

  .frame-desc h1 {
    @apply m-0 text-2xl text-ina2 bg-ina1 px-4 border-4 rounded-tl-xl border-ina3; 
  }

  .frame-desc p {
    @apply m-0 pl-8 pr-8 pt-1 pb-1 text-xl text-fg rounded-bl-xl border-4 h-[auto] flex-auto border-t-0 text-fg border-ina3; 

  }
  
  .frame-desc {
    @apply w-[50%] flex flex-col;
  }
  
  .frame-body h1 {
    @apply m-0 text-2xl bg-ina1 text-ina2 px-4 border-4 rounded-t-xl border-ina3; 
  }

  .frame-body p {
    @apply m-0 p-8 text-xl text-fg rounded-b-xl border-4 border-t-0 border-ina3; 
  }

  .frame-body .center {
    @apply text-center m-0 p-6 text-3xl text-ina2 font-bold; 
    font-family: 'Fira Mono', bold;
    columns: 3;
  }
  
  .frame-body {
    @apply w-[100%] ;
  }
  
  .frame-image {
    @apply w-[50%];
  }
 
  .frame-image img {
    @apply h-[100%] w-[100%] border-4 rounded-r-xl border-l-0 border-ina3;
    object-fit: cover;
  }

  ul {
    list-style-type: circle;
    list-style-position: inside;
  }

  p img {
    @apply rounded-xl overflow-hidden ml-auto mr-auto max-w-[100%] center;
  }

  pre {
    @apply bg-bg mt-4 mb-2 p-4 overflow-auto rounded-xl;
  }
 
  pre code {
    @apply text-fg bg-bg rounded-lg;
    padding: 0px;
  }

  code {
    @apply text-fg bg-bg rounded-lg px-2;
  }

  @media only screen and (max-width: 900px) {
    .frame-bg {
      @apply rounded-xl
             mx-8 my-8
             flex flex-col-reverse;
    }
    
    .frame-container-noanim {
      @apply rounded-xl overflow-hidden
             mx-8 my-8
             flex flex-col-reverse
             items-stretch
             transition-all;
    }
  
    .frame-image {
      @apply w-[100%];
    }
  
    .frame-image img {
      border-width: 4px;
      border-top-width: 0px;
      @apply rounded-xl rounded-t-none;
    }
  
    .frame-desc {
      @apply w-[100%];
    }
  
    a:nth-child(odd) .frame-container {
      @apply flex-col-reverse;
    }
    
    .frame-body .center {
      @apply text-center m-0 p-6 text-base font-bold;   
      columns: 2;
    }

    .frame-body p {
      @apply m-0 p-4 text-base rounded-xl rounded-t-none; 
    }

    .frame-desc p {
      @apply m-0 p-4 text-base rounded-none; 
    }

    .frame-body h1 {
      @apply text-xl rounded-xl rounded-b-none; 
    }

    .frame-desc h1 {
      @apply text-xl rounded-xl rounded-b-none; 
    }

    h1 {
      @apply text-2xl;
    }

    h2 {
      @apply text-xl;
    }

    h3 {
      @apply text-lg font-bold;
    }

    p {
      @apply text-base;
    }

    .main {
      @apply fixed left-16 p-4 right-0 top-8 bottom-0;
      overflow-y: auto;
    }
  }
}
